import './App.css';

function App() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-3xl font-bold text-gray-400">
        Reastyle
      </h1>
      <span className="text-normal font-normal text-gray-800">
        Kommt bald..
      </span>
    </div>
  );
}

export default App;
